.outline {
  display: inline-block;
  color: #ffffff; /* 文字の色 */
  letter-spacing: 6px; /* 文字間 */
  text-shadow:
    3px 3px 3px #e00065,
    -3px 3px 3px #e00065,
    3px -3px 3px #e00065,
    -3px -3px 3px #e00065,
    3px 0px 3px #e00065,
    0px 3px 3px #e00065,
    -3px 0px 3px #e00065,
    0px -3px 3px #e00065; /* 文字の影 */
}

.flex-wrap {
  display: flex;
  /* outline: auto; */
}
.left-column {
  white-space: nowrap;
  /* background-color: #ccc; */
  /* outline: auto; */
  padding: 10px;
}
.right-column {
  width: 100%;
  /* background-color: #eee; */
  padding: 10px;
}

.original-gradient {
  /* height: 200px; */
  overflow: hidden;
  /* width: 80%; */
  background-image: linear-gradient(90deg, rgba(209, 253, 254, 1), rgba(253, 219, 146, 1));
}

.anker-gradient {
  border-radius: 4px;
  padding-left: 4px;
  padding-right: 4px;
  background-color: #139bc9;
  /* background-image: linear-gradient(90deg, rgba(243, 170, 196, 1), rgba(255, 247, 216, 1) 94%); */
}

.anker-group {
  margin-top: 12px;
  border-radius: 8px;
  border-width: 1px;
  border-color: #aaa;
  border-style: solid;
  padding: 3px;
  padding-top: 12px;
  position: relative;
}

.anker-group-title {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.00938em;
  position: absolute;
  background-color: #fff;
  top: -10px;
  border-radius: 4px;
  border-width: 1px;
  border-color: #aaa;
  border-style: solid;
  line-height: 16px !important;
  font-size: 12px !important;
  padding-left: 2px;
  padding-right: 2px;
}
