.outline-roulette-nagisa {
    display: inline-block;
    color: #ffffff; /* 文字の色 */
    letter-spacing: 6px; /* 文字間 */
    text-shadow:
      3px 3px 3px #77a8e2,
      -3px 3px 3px #77a8e2,
      3px -3px 3px #77a8e2,
      -3px -3px 3px #77a8e2,
      3px 0px 3px #77a8e2,
      0px 3px 3px #77a8e2,
      -3px 0px 3px #77a8e2,
      0px -3px 3px #77a8e2; /* 文字の影 */
  }
  
  .outline-roulette-unorabi {
    display: inline-block;
    color: #ffffff; /* 文字の色 */
    letter-spacing: 6px; /* 文字間 */
    text-shadow:
      3px 3px 3px #f6c82b,
      -3px 3px 3px #f6c82b,
      3px -3px 3px #f6c82b,
      -3px -3px 3px #f6c82b,
      3px 0px 3px #f6c82b,
      0px 3px 3px #f6c82b,
      -3px 0px 3px #f6c82b,
      0px -3px 3px #f6c82b; /* 文字の影 */
  }
  
